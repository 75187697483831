body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.title-container {
  width: 100%;
  text-align: center;
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}

.title-container h1 {
  font-size: 4.5em;
}

.panel {
  padding: 1em;
  background: whitesmoke;
  text-align: center;
  margin-bottom: 2em;
}

